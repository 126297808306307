import bh from '../../bh.png';
import './Brand.css'

const Brand = () => {
  return (
     <div className="brand-wrapper">
        <a href='https://www.bluehost.com/' className="brand-box" target="_blank" rel="noreferrer">
          <img src={bh} alt="" />
        </a>
     </div>
  )
}

export default Brand