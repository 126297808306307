import React, { useState } from 'react';
import './SpinWheel.css';
import sunglassesImg from '../../img/sunglasses.png';
import bottleImg from '../../img/water-bottle.png';
import diaryImg from '../../img/diary.png';
import giftImg from '../../img/gift.png';
import betterLuckImg from '../../img/better-luck.png';
import { useCookies } from 'react-cookie';

const items = [
  { label: 'Sunglasses', probability: 26.66, color: 'hsl(197 30% 43%)', icon: sunglassesImg },
  { label: 'Diary', probability: 26.66, color: 'hsl(173 58% 39%)', icon: diaryImg },
  { label: 'Bottle', probability: 26.66, color: 'hsl(43 74% 66%)', icon: bottleImg },
  { label: 'All 3', probability: 5, color: 'hsl(27 87% 67%)', icon: giftImg },
  { label: 'Better Luck Next time', probability: 15, color: 'hsl(12 76% 61%)', icon: betterLuckImg },
];

const SpinWheel = () => {
  const [selectItem, setselectItem] = useState(null);
  const [animate, setAnimate] = useState(false);
  const [cookies, setCookie] = useCookies();

  const select = () => {
    if (selectItem === null) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 3800);

      let totalProbability = 0;
      totalProbability = items.reduce((acc, cur) => acc + cur.probability, 0);

      let randomNum = Math.floor(Math.random() * totalProbability) + 1;

      let sum = 0;

      for (let i = 0; i < items.length; i++) {
        sum += items[i].probability;

        if (randomNum <= sum) {
          setselectItem(i);
          // console.log(items[i]);
          break;
        }
      }

      // window.onbeforeunload = function() {
      //   return "Are you sure you want to leave?";
      // };
      window.onunload = function () {
        setCookie('gamePlayed', true, { path: '/', maxAge:  24 * 3600  });
      };
    } else {
      setselectItem(null);
    }
  };

  const wheelVars = {
    '--nb-item': items.length,
    '--selected-item': selectItem,
  };

  const spinning = selectItem !== null ? 'spinning' : '';
  return (
    <div>
      <header>
        <p className="sub-heading">Spin The Wheel!</p>
      </header>
      <div className="wheel-container">
        <div
          className={`wheel ${spinning}`}
          style={{
            ...wheelVars,
            background: `conic-gradient(
      from -90deg,
      ${items.map(({ color }, i) => `${color} 0 ${(100 / items.length) * (items.length - i)}%`).reverse()}
    )`,
          }}
        >
          {items.map((item, index) => (
            <div className={`wheel-item ${item.icon ? 'with-icon' : ''}`} key={index} style={{ '--item-nb': index }}>
              {item.icon ? (
                <div className="wheel-item-iconbox">
                  <img src={item.icon} alt={item.label} />
                </div>
              ) : (
                <span>{item.label}</span>
              )}
            </div>
          ))}
        </div>
        <div className={`ticker ${animate ? 'animate' : ''}`}></div>
        <button className="spin-btn" onClick={select} disabled={selectItem !== null}>
          Spin
        </button>
      </div>
      {!animate && selectItem !== null ? (
        <div className="spin-result">
          {selectItem !== 4 ? (
            <h2 className="win-heading">
              Congratulations you won <span>{items[selectItem].label}</span>{' '}
            </h2>
          ) : (
            <h2 className="loss-heading">Sorry, better luck next time</h2>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SpinWheel;
