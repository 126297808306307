import axios from 'axios'
export const sendDataToCustomerIo = async (values, bluehostCustomer, formQuestions) => {
    let data = {
        action: "identify",
        type: "person",
        identifiers: {
            id: values.email,
        },
        attributes: {
            Source: 'Wordcamp Kerala 2023',
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            isBluehostCustomer: bluehostCustomer,
            wordpressExperience: formQuestions.userWordpressExperience,
            buildWebsitesFor: formQuestions.buildWebsitesFor,
            platformsToKeepUpToDateWithWordpress: formQuestions.toKeepUpdateOnWordpress,
            interestedForBluehostAdvisoryPanel: formQuestions.bluehostAdvisoryPanel
        }
    }
    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://track.customer.io/api/v2/entity',

        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Basic NjVmM2U0NmRkOTQzMjljOTI4Njg6ZTExMjYwNjNlZjFmNTFiY2M3YTc='
        },
        data: JSON.stringify(data)
    };
    const res = await axios.request(config)
    return res
}

export const checkEmailInCustomerIo = async (email) => {
    const getConfig = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://api.customer.io/v1/customers?email=${email}`,
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer e64c47d0a1d0fa6a2e8ee47ce41e5080',
        }
    };
    const res = await axios.request(getConfig)
    if (res.data.results.length) {
        return res.data.results
    }
    return []
}
