import React, { useEffect, useState } from 'react';
import './FormComponent.css';
import { useFormik } from 'formik';
import { userSchema } from '../schemas';
import { sendDataToCustomerIo } from '../../customerIoHelper'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
};

const FormComponent = ({ handleDataSubmitted }) => {
  const [bluehostCustomer, setBluehostCustomer] = useState(false)
  const [formQuestions, setFormQuestions] = useState({
    userWordpressExperience: '',
    buildWebsitesFor: '',
    toKeepUpdateOnWordpress: [],
    bluehostAdvisoryPanel: false
  })
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      try {
        await sendDataToCustomerIo(values, bluehostCustomer, formQuestions)
        handleDataSubmitted(true);
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          let msg = "Form submission failed. Please check your network, and if you have an ad blocker, turn it off and try again."
          toast.error(msg)
        } else {
          toast.error(error.message)
        }
      }
    },
  });

  useEffect(() => {
    handleDataSubmitted(false);
    window.localStorage.clear();
  }, [handleDataSubmitted]);

  const addToArray = (e) => {
    let { value, checked } = e.target
    const { toKeepUpdateOnWordpress } = formQuestions
    if (checked) {
      if (!toKeepUpdateOnWordpress.includes(value)) {
        setFormQuestions({ ...formQuestions, toKeepUpdateOnWordpress: [...formQuestions.toKeepUpdateOnWordpress, value] })
      }
    } else {
      if (toKeepUpdateOnWordpress.includes(value)) {
        setFormQuestions({ ...formQuestions, toKeepUpdateOnWordpress: toKeepUpdateOnWordpress.filter(val => val !== value) })
      }
    }
  }

  return (
    <div>
      <ToastContainer></ToastContainer>
      <header>
        <p className="sub-heading">Spin to Win!</p>
        <ul className="breadcrumb"> <li> Fill this short survey </li>
          <li className="breadcrumb-separator"> {'>'} </li> <li> Spin the wheel </li>
          <li className="breadcrumb-separator"> {'>'} </li> <li> Share on social </li>
          <li className="breadcrumb-separator"> {'>'} </li> <li> Take home cool swag! </li>
        </ul> <p className="para-one">When you sign up for our mailing list, you'll get the latest WordPress news and updates from Bluehost.</p>
      </header>
      <form onSubmit={handleSubmit} className="form">
        <div className="input-box">
          <label>
            <span className="required">* </span>First Name
          </label>
          <input name="firstname" value={values.firstname} onChange={handleChange} onBlur={handleBlur} type="text" maxLength={50} />

          <div className="characterCount">
            {errors.firstname && touched.firstname ? <p className="error-msg">{errors.firstname}</p> : <p></p>}
            <span>{values.firstname.length}/50</span>
          </div>
        </div>
        <div className="input-box">
          <label>
            <span className="required">* </span>Last Name
          </label>
          <input name="lastname" value={values.lastname} onChange={handleChange} onBlur={handleBlur} type="text" maxLength={50} />

          <div className="characterCount">
            {errors.lastname && touched.lastname ? <p className="error-msg">{errors.lastname}</p> : <p></p>}
            <span>{values.lastname.length}/50</span>
          </div>
        </div>
        <div className="input-box">
          <label>
            <span className="required">* </span>Email Address
          </label>
          <input name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} type="text" maxLength={50} />
          <div className="characterCount">
            {errors.email && touched.email ? <p className="error-msg">{errors.email}</p> : <p></p>}
            <span>{values.email.length}/50</span>
          </div>
        </div>
        <div className="radio-check-box">
          <h3> <span className="required">* </span>Are you currently a Bluehost Customer?</h3>
          <div className="radio-check-box-option">
            <div className="option">
              <input type="radio" id="optionCustomer-check-yes" name="optionCustomer" value={true} required onChange={(e) => setBluehostCustomer(e.target.value)} />
              <label htmlFor="optionCustomer-check-yes">Yes</label>
            </div>
            <div className="option">
              <input type="radio" id="optionCustomer-check-no" name="optionCustomer" value={false} required onChange={(e) => setBluehostCustomer(e.target.value)} />
              <label htmlFor="optionCustomer-check-no">No</label>
            </div>
          </div>
        </div>
        <div className="radio-check-box">
          <h3> <span className="required">* </span>How would you rate your experience with WordPress?</h3>
          <div className="radio-check-box-option">
            <div className="option">
              <input type="radio" id="wordpressExperience-check-1" name="wordpressExperience" value='1 - Beginner (getting started with WordPress)' required onChange={(e) => setFormQuestions({ ...formQuestions, userWordpressExperience: e.target.value })} />
              <label htmlFor="wordpressExperience-check-1">1 - Beginner (getting started with WordPress)</label>
            </div>
            <div className="option">
              <input type="radio" id="wordpressExperience-check-2" name="wordpressExperience" value='2 - Intermediate (some experience with WordPress)' required onChange={(e) => setFormQuestions({ ...formQuestions, userWordpressExperience: e.target.value })} />
              <label htmlFor="wordpressExperience-check-2">2 - Intermediate (some experience with WordPress)</label>
            </div>
            <div className="option">
              <input type="radio" id="wordpressExperience-check-3" name="wordpressExperience" value='3 - Advanced (detailed understanding of the WordPress platform)' required onChange={(e) => setFormQuestions({ ...formQuestions, userWordpressExperience: e.target.value })} />
              <label htmlFor="wordpressExperience-check-3">3 - Advanced (detailed understanding of the WordPress platform)</label>
            </div>
          </div>
        </div>
        <div className="radio-check-box">
          <h3> <span className="required">* </span>I build websites for</h3>
          <div className="radio-check-box-option">
            <div className="option">
              <input type="radio" id="buildWebsiteFor-check-yes" name="buildWebsiteFor" value='Myself' required onChange={(e) => setFormQuestions({ ...formQuestions, buildWebsitesFor: e.target.value })} />
              <label htmlFor="buildWebsiteFor-check-yes">Myself</label>
            </div>
            <div className="option">
              <input type="radio" id="buildWebsiteFor-check-no" name="buildWebsiteFor" value='Others' required onChange={(e) => setFormQuestions({ ...formQuestions, buildWebsitesFor: e.target.value })} />
              <label htmlFor="buildWebsiteFor-check-no">Others</label>
            </div>
          </div>
        </div>
        <div className="radio-check-box">
          <h3> <span className="required">* </span>Would you be interested in joining Bluehost's advisory panel should the need arise? This is an online forum that we may use to understand customer needs in regard to WordPress, Hosting and any new product development.</h3>
          <div className="radio-check-box-option">
            <div className="option">
              <input type="radio" id="bluehostAdvisoryPanel-check-yes" name="bluehostAdvisoryPanel" value={true} required onChange={(e) => setFormQuestions({ ...formQuestions, bluehostAdvisoryPanel: e.target.value })} />
              <label htmlFor="bluehostAdvisoryPanel-check-yes">Yes</label>
            </div>
            <div className="option">
              <input type="radio" id="bluehostAdvisoryPanel-check-no" name="bluehostAdvisoryPanel" value={false} required onChange={(e) => setFormQuestions({ ...formQuestions, bluehostAdvisoryPanel: e.target.value })} />
              <label htmlFor="bluehostAdvisoryPanel-check-no">No</label>
            </div>
          </div>
        </div>
        <div className="radio-check-box">
          <h3> <span className="required">* </span>Where do you go to keep up to date on WordPress? Select all that apply</h3>
          <div className="radio-check-box-option">
            <div className="option">
              <input type="checkbox" id="toKeepUpdateOnWordpress-check-1" name="toKeepUpdateOnWordpress" value='Slack Channels' onChange={(e) => addToArray(e)} />
              <label htmlFor="toKeepUpdateOnWordpress-check-1">Slack Channels</label>
            </div>
            <div className="option">
              <input type="checkbox" id="toKeepUpdateOnWordpress-check-2" name="toKeepUpdateOnWordpress" value='Facebook Groups' onChange={(e) => addToArray(e)} />
              <label htmlFor="toKeepUpdateOnWordpress-check-2">Facebook Groups</label>
            </div>
            <div className="option">
              <input type="checkbox" id="toKeepUpdateOnWordpress-check-3" name="toKeepUpdateOnWordpress" value='Reddit' onChange={(e) => addToArray(e)} />
              <label htmlFor="toKeepUpdateOnWordpress-check-3">Reddit</label>
            </div>
            <div className="option">
              <input type="checkbox" id="toKeepUpdateOnWordpress-check-4" name="toKeepUpdateOnWordpress" value='In person community groups' onChange={(e) => addToArray(e)} />
              <label htmlFor="toKeepUpdateOnWordpress-check-4">In person community groups</label>
            </div>
            <div className="option">
              <input type="checkbox" id="toKeepUpdateOnWordpress-check-5" name="toKeepUpdateOnWordpress" value='WordPress events' onChange={(e) => addToArray(e)} />
              <label htmlFor="toKeepUpdateOnWordpress-check-5">WordPress events</label>
            </div>
            <div className="option">
              <input type="checkbox" id="toKeepUpdateOnWordpress-check-6" name="toKeepUpdateOnWordpress" value='Podcasts' onChange={(e) => addToArray(e)} />
              <label htmlFor="toKeepUpdateOnWordpress-check-6">Podcasts</label>
            </div>
            <div className="option">
              <input type="checkbox" id="toKeepUpdateOnWordpress-check-7" name="toKeepUpdateOnWordpress" value='Blogs' onChange={(e) => addToArray(e)} />
              <label htmlFor="toKeepUpdateOnWordpress-check-7">Blogs</label>
            </div>
            <div className="option">
              <input type="checkbox" id="toKeepUpdateOnWordpress-check-8" name="toKeepUpdateOnWordpress" value='Others' onChange={(e) => addToArray(e)} />
              <label htmlFor="toKeepUpdateOnWordpress-check-8">Others</label>
            </div>
            <div className="option">
              <input type="checkbox" id="toKeepUpdateOnWordpress-check-9" name="toKeepUpdateOnWordpress" value='I do not keep update on WordPress' onChange={(e) => addToArray(e)} />
              <label htmlFor="toKeepUpdateOnWordpress-check-9">I do not keep update on WordPress</label>
            </div>
          </div>
        </div>
        <div className="input-btn">
          <button type="submit">Submit</button>
        </div>
        <p className="note">
          <small>If you just opted in, you're consenting to receive marketing emails from: Bluehost India, Nesco IT Park, Mumbai, Maharashtra, 400063, India. You can revoke your consent to receive emails at any time by using the Unsubscribe link, found at the bottom of every email.</small>
        </p>
      </form>
    </div>
  );
};

export default FormComponent;
