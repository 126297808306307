import { useEffect, useState } from 'react';
import Brand from './components/brand/Brand';
import FormComponent from './components/form/FormComponent';
import ShareContainer from './components/share/ShareContainer';
import SpinWheel from './components/SpinWheel/SpinWheel';
import { useCookies } from 'react-cookie';

function App() {
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [cookies, setCookie] = useCookies();

  const handleDataSubmitted = (value) => {
    setDataSubmitted(value);
  };

  if (cookies.gamePlayed) {
    return (
      <div className="App">
        <div className="container thank-you-container">
          <h2>Thank You!</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="container">
        <Brand />
        {!dataSubmitted && <FormComponent handleDataSubmitted={handleDataSubmitted}></FormComponent>}
        {dataSubmitted && (
          <>
            <SpinWheel />
            <ShareContainer />
          </>
        )}
      </div>
    </div>
  );
}

export default App;
